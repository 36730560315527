import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { variant as styleVariant } from 'styled-system';
import css from '@styled-system/css';
import { ChevronRight } from 'react-feather';

import Link from 'legacy/components/link';
import Flex from 'legacy/components/flex';
import Box from 'legacy/components/box';

const StyledLink = styled(Flex)(
  css({
    alignItems: 'center',
    '& > a': {
      textDecoration: 'none',
    },
  }),
  styleVariant({
    variants: {
      medium: {
        fontSize: 2,
      },
      large: {
        fontSize: [2, null, null, 3],
      },
    },
  })
);

const MobileIconWrapper = styled(Box)(
  css({
    display: ['flex', null, null, 'none'],
    ml: '2px',
  })
);

const DesktopIconWrapper = styled(Box)(
  css({
    display: ['none', null, null, 'flex'],
    ml: '2px',
  }),
  styleVariant({
    variants: {
      medium: {
        pt: 0,
      },
      large: {
        pt: '2px',
      },
    },
  })
);

const CtaLink = ({ href, children, external, color, variant, ...props }) => {
  const theme = useContext(ThemeContext);
  return (
    <StyledLink as="span" mt={3} variant={variant} {...props}>
      <Link href={href} color={color} external={external}>
        {children}
      </Link>
      <MobileIconWrapper as="span" ml={1} display={['flex', null, null, 'none']}>
        <ChevronRight size={16} strokeWidth={3} color={theme.colors[color]} />
      </MobileIconWrapper>
      <DesktopIconWrapper as="span" variant={variant}>
        <ChevronRight
          size={variant === 'large' ? 20 : 16}
          strokeWidth={3}
          color={theme.colors[color]}
        />
      </DesktopIconWrapper>
    </StyledLink>
  );
};

CtaLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['medium', 'large']),
};

CtaLink.defaultProps = {
  external: false,
  color: 'teal',
  variant: 'large',
};

export default CtaLink;
