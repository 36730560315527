import { logger } from 'utils/logger';

/**
 * Converts a Contentful image URL to an Imgix URL
 *
 * @param   {String} fileUrl The original file URL provided by Contentful
 *
 * @returns {String}         The Imgix URL for the provided asset
 */
const getContentfulImgixUrl = (fileUrl) => {
  try {
    const imgixUrl = fileUrl.includes('https')
      ? fileUrl.replace(
          `https://images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
          process.env.NEXT_PUBLIC_IMGIX_CONTENTFUL_BASE_URL
        )
      : fileUrl.replace(
          `//images.ctfassets.net/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
          process.env.NEXT_PUBLIC_IMGIX_CONTENTFUL_BASE_URL
        );

    return imgixUrl;
  } catch (err) {
    logger.error('Error parsing Contentful image string', fileUrl, err);
    return '';
  }
};

export default getContentfulImgixUrl;
