import PropTypes from 'prop-types';

export const contentfulSysType = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export type ContentfulSys = {
  id: string;
};

export type ContentfulSysInterface = {
  sys: ContentfulSys;
};
