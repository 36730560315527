import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const slideInFromRight = (from = 101) => keyframes`
  from {
    transform: translateX(${from}%)
  }
  to {
    transform: translateX(0%)
  }
`;

export default {
  fadeIn,
};
