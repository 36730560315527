import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';
import { contentfulLinkType } from 'types/common/contentfulLink.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';

const commonProps = {
  bgColor: PropTypes.oneOf(['white', 'hiaCream']).isRequired,
  mobileLogo: contentfulMediaType.isRequired,
  desktopLogo: contentfulMediaType.isRequired,
  logoUrl: PropTypes.string,
  cta: ctaType.isRequired,
  secondaryCta: ctaType.isRequired,
};

export const headerNavProps = {
  navItems: PropTypes.arrayOf(PropTypes.oneOfType([contentfulLinkType])).isRequired,
};

export const headerNavType = PropTypes.shape({
  ...commonProps,
  navigationItemsCollection: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.oneOfType([contentfulLinkType])),
  }),
});
