import styled from 'styled-components';
import { variant, color, space, compose } from 'styled-system';
import css from '@styled-system/css';

import Box from 'legacy/components/box';

const Divider = styled(Box)(
  css({
    borderWidth: 0,
  }),
  variant({
    variants: {
      thick: {
        height: 2,
        bg: 'gray90',
      },
      thin: {
        height: 1,
        bg: 'gray100',
      },
    },
  }),
  compose(space, color)
);

Divider.defaultProps = {
  variant: 'thin',
};

export default Divider;
