/* eslint-disable no-console */
/* istanbul ignore file */
import { datadogLogs } from '@datadog/browser-logs';
import vwoSdk from 'vwo-node-sdk';

import rollbar from 'utils/rollbar';

// Apparently VWO just doesn't care what we pass into the "level" property.
// If something exists here, it will be logged to regardless.
export const browserLogger = {
  logger: {
    log: (level, message) => {
      switch (level) {
        // case vwoSdk.logging.LogLevelEnum.DEBUG:
        //   datadogLogs.logger.debug(message);
        //   break;
        // case vwoSdk.logging.LogLevelEnum.INFO:
        //   datadogLogs.logger.info(message);
        //   break;
        case vwoSdk.logging.LogLevelEnum.ERROR:
          rollbar.error(message);
          datadogLogs.logger.error(message);
          break;
        case vwoSdk.logging.LogLevelEnum.WARN:
          datadogLogs.logger.warn(message);
          console.warn(message);
          break;
        default:
          // datadogLogs.logger.log(message);
          // console.log(message);
          break;
      }
    },
  },
  level: vwoSdk.logging.LogLevelEnum.WARN,
};
