// TODO: A regex would probably be more efficient?
/**
 * Returns true if the URL should be opened in a new tab
 *
 * @param {string} url A full or partial URL
 * @returns true if the URL should be opened in a new tab
 */
const shouldOpenNewTab = (url) => {
  if (!url?.length) return null;
  // return false by default for internal links or internal URL fragments
  return (
    (!url?.includes('humaninterest.com') &&
      !url?.includes('#') &&
      Boolean(url.match(/\.(\w+)/))) ||
    url?.includes('tel:') ||
    url?.includes('mailto:') ||
    url?.includes('start.humaninterest.com') ||
    url?.includes('get.humaninterest.com') ||
    url?.includes('humaninterest.com/learn/guides/') ||
    (url.includes('/learn/guides') && !url.match(/\.(\w+)/))
  );
};

export default shouldOpenNewTab;
