/* eslint-disable react/no-array-index-key, react/destructuring-assignment */
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import css from '@styled-system/css';

import Text from 'legacy/components/text';
import Heading from 'legacy/components/heading';
import Divider from 'legacy/components/divider';
import shouldOpenNewTab from 'utils/shouldOpenNewTab';
import Link from 'legacy/components/link';

const StyledListItem = styled.li(
  css({
    ml: 4,
    mt: 2,
    '& p': {
      mt: 0,
    },
    '& b': {
      fontWeight: 600,
      color: 'blue',
    },
  })
);

const StyledHeading = styled(Heading)(
  css({
    '& + p, & + ul': {
      mt: 3,
    },
  })
);

const SimpleRichText = ({ content, links, renderOptions = {}, ...props }) => {
  // Default options
  // One gotcha here is that if you override `[BLOCKS.EMBEDDED_ENTRY]` the entire switch statement needs to be provided
  const dtrOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <StyledHeading as="h1" mt={4} fontSize={['4', null, '5', null, '6']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <StyledHeading as="h2" mt={4} fontSize={['3', null, '4', null, '5']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <StyledHeading as="h3" mt={4} fontSize={['2', null, '3', null, '4']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <StyledHeading as="h4" mt={3} fontSize={['2', null, null, null, '3']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <StyledHeading as="h5" mt={2} fontSize={2}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <Text {...props}>{children}</Text>,
      [BLOCKS.LIST_ITEM]: (node, children) => <StyledListItem>{children}</StyledListItem>,
      [BLOCKS.HR]: () => <Divider as="hr" my={5} bg="gray90" />,
      [INLINES.HYPERLINK]: (node, children) =>
        shouldOpenNewTab(node.data?.uri) ? (
          <Link href={node.data?.uri} external target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        ) : (
          <Link href={node.data?.uri}>{children}</Link>
        ),
    },
    renderMark: {
      // eslint-disable-next-line react/no-danger
      [MARKS.CODE]: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
    },
  };

  const combinedOptions = {
    renderNode: {
      ...dtrOptions.renderNode,
      ...renderOptions?.renderNode,
    },
    renderMark: {
      ...dtrOptions.renderMark,
      ...renderOptions?.renderMark,
    },
  };
  return documentToReactComponents(content, combinedOptions);
};

SimpleRichText.propTypes = {
  content: PropTypes.exact({
    data: PropTypes.object.isRequired,
    content: PropTypes.array.isRequired,
    nodeType: PropTypes.oneOf(['document']).isRequired,
  }),
  renderOptions: PropTypes.shape({
    renderNode: PropTypes.object,
    renderMark: PropTypes.object,
  }),
};

export default SimpleRichText;
