import PropTypes from 'prop-types';

import Button from 'legacy/components/button';
import CtaLink from 'legacy/components/ctaLink';
import { track } from 'utils/analytics';

/**
 * @deprecated
 */
const Cta = ({
  location,
  type = 'button',
  label = 'Get Started',
  url = '/signup/',
  btnSize = 'md',
  btnVariant = 'secondary',
  ...props
}) => {
  const handleCtaClick = () => {
    track('cta_click', {
      cta_type: location,
      cta_text: label,
      cta_url: url,
    });
  };

  const handleCtaDown = (e) => {
    if (e.button > 0) {
      let mouseButton;

      switch (e.button) {
        case 1:
          mouseButton = 'middle_mouse';
          break;
        case 2:
          mouseButton = 'right_mouse';
          break;
        default:
          mouseButton = 'mouse';
      }

      track('cta_click', {
        cta_type: location,
        cta_text: label,
        cta_url: url,
        mouse_button: mouseButton,
      });
    }
  };

  if (type === 'link') {
    return (
      <CtaLink href={url} onClick={handleCtaClick} onMouseDown={handleCtaDown} {...props}>
        {label}
      </CtaLink>
    );
  }

  return (
    <Button
      as="a"
      href={url}
      onClick={handleCtaClick}
      onMouseDown={handleCtaDown}
      variant={btnVariant}
      btnSize={btnSize}
      {...props}
    >
      {label}
    </Button>
  );
};

Cta.propTypes = {
  type: PropTypes.oneOf(['link', 'button']),
  label: PropTypes.string,
  url: PropTypes.string,
  location: PropTypes.string.isRequired,
  btnSize: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  ]),
  btnVariant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'secondaryGradient']),
};

export default Cta;
