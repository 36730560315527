import PropTypes from 'prop-types';

import { btnSizes, btnVariants } from 'humanity/primitives/button';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { BtnSizes, BtnVariants } from 'humanity/primitives/button/button';

/**
 * @deprecated
 */
const commonProps = {
  type: PropTypes.oneOf(['link', 'button']),
  label: PropTypes.string,
  url: PropTypes.string,
  openInNewTab: PropTypes.bool,
};
type CommonProps = {
  type: 'link' | 'button';
  label: string;
  url: string;
  openInNewTab: boolean;
};

/**
 * @deprecated
 */
export const ctaProps = {
  ...commonProps,
  location: PropTypes.string.isRequired,
  btnSize: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf([...btnSizes])),
    PropTypes.oneOf([...btnSizes]),
  ]),
  btnVariant: PropTypes.oneOf([...btnVariants]),
};
export type CtaProps = CommonProps & {
  location: string;
  btnSize: BtnSizes;
  btnVariant: BtnVariants;
};
/**
 * @deprecated
 */
export const ctaType = PropTypes.shape({
  ...commonProps,
  sys: contentfulSysType.isRequired,
  variant: PropTypes.oneOf([...btnVariants]),
});
